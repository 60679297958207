import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import GreenBar from '../sections/greenBar4'
import Team from '../sections/team'
import { PageHead } from '../common'

export default () => (
  <Layout>
    <SEO
      title="Our Team"
      description="Underwood’s has developed a formal educational training program through GIA and the American Gem Society for all of our employees. This gives us the most educated jewelry staff in Jacksonville, FL." />
    <PageHead>
      <h1>OUR TEAM</h1>
      <p>Underwood’s believes that every customer that comes into our store for a diamond, watch or jewelry has the right to be served by a trained and experienced professional associate. Because of this, Underwood’s has an extensive and thorough training program that encompasses gemological knowledge as well as customer service issues.</p>
      <p>This formal program through the American Gem Society (AGS) and the Gemological Institute of America (GIA) allows our staff to stay on the cutting edge of new developments and trends as they are happening in our industry. Our training program is required of all associates whether they come to us with a background in the jewelry industry or no experience in our industry. After approximately 18 months in our training program our staff members are ready to take the AGS Way Test earning the professional title, Registered Jeweler, AGS. We also have three Certified Gemologists on our staff.</p>
    </PageHead>
    <GreenBar />
    <Team />
    <JoinUs />
  </Layout>
)
