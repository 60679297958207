import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { colors, md } from '../common'

export default () => (
  <StaticQuery
    query={graphql`
      query TeamQuery {
        allStaffJson {
          edges {
            node {
              name
              title
              year
            }
          }
        }
      }
      `}
      render={ data => (
        <Wrap>
          {data.allStaffJson.edges.map((staff, index) => {
            return  <Card key={index}>
                      <p css={`color:${colors.green};font-size:20px;text-transform:uppercase;`}>{staff.node.name}</p>
                      <br />
                      <p>{staff.node.title}</p>
                      <p css={`color:${colors.grey}`}>{staff.node.year}</p>
                    </Card>
          })}
        </Wrap>
      )}
  />
)

const Card = styled.div`
  width: 26%;
  text-align:center;
  margin: .5rem;
  background-color:white;
  padding: 2rem;

  p {
    margin:0
  }

  ${md} {
    width:100%;
  }
`

const Wrap = styled.div`
  background-color: ${colors.greyLight};
  padding: 4rem;
  display:flex;
  flex-wrap: wrap;
  justify-content:space-around;

  ${md} {
    padding: 1rem;
  }
`
